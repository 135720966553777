import { useState, useEffect } from "react";
import configs from "../functions/configs";
import sortBibGroup from "../functions/sortBibGroup";

const useItemFetch = (url, isBranchCard = false) => {
  const [bib, setBib] = useState(null);
  const [group, setGroup] = useState(null);
  const conf = configs();

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setBib(data);
        let group_identifier = 'zilch';
        try { 
          group_identifier = data.response.docs[0].ss_grouping; 
          if (isBranchCard) { // allow for suppressed editions
            fetch (conf.selectAPI + '?rows=99&fq=ss_grouping%3A' + group_identifier)
            .then((res2) => res2.json())
            .then((data2) => {
              data2.response.docs = sortBibGroup(data2.response.docs);
              setGroup(data2);
            })
          } else { // don't show suppressed editions
            fetch (conf.selectAPI + '?rows=99&fq=suppressed%3Afalse&fq=ss_grouping%3A' + group_identifier)
            .then((res2) => res2.json())
            .then((data2) => {
              data2.response.docs = sortBibGroup(data2.response.docs);
              setGroup(data2);
            })
          }
          
        } catch {}
      });
    
  }, [url, isBranchCard]);

  return {bib:bib, group:group};

};

export default useItemFetch;