import itemStatus from "../../functions/itemStatus";
import availabilityString from "../../functions/availabilityString";

const FormatLink = (props) => {

  
  
  try {

      let format = '';
      let year = '';
      let status = '';
      let availability = '';

      const url = new URL(window.location);
      let urlBib = url.searchParams.get('b');

      try {
        if (props.data.material_type) {
          format = props.data.material_type;
        }
        if (props.data.publishYear) {
          year = ' - ' + props.data.publishYear;
        }
        status = itemStatus(props.data);
        availability = availabilityString(props.data);
      } catch {}

      let statusClasses = "status";
      if (status === 'Available Now' || status === 'Always Available') {
        statusClasses = "status color-available";
      } else {
        statusClasses = "status color-pending";
      }

      let linkClasses = 'individual-format';

      if (props.data.id === urlBib) {
        linkClasses = 'individual-format format-selected';
      }

      if (format === 'Archival Collection') {
        statusClasses = "status color-available";
        status = "Available Now";
        availability = 'Access available on request';
      }

      return (
        <a onClick={props.updateItemPage} bib={props.data.id} aria-label="select-this-format" href="#" className={linkClasses}>
          <div className="bold">{format} {year}</div>
          <div className={statusClasses}>{status}</div>
          <div className="availability">{availability}</div>
        </a>
      )
    
  } catch {}  
  
};

export default FormatLink;