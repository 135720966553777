import configs from "../../functions/configs";
import { useState } from "react";
import IconHashtag from "../icons/iconHashtag";
import IconLandmark from "../icons/iconLandmark";
import IconCalendarTimes from "../icons/iconCalendarTimes";
import IconCalendarPlus from "../icons/iconCalendarPlus";
import IconTag from "../icons/iconTag";
import IconTruck from "../icons/iconTruck";

const ItemLocationTable = (props) => {
  const conf = configs();
  const [availableChecked, setAvailableChecked] = useState(false);

  const isUsingStaffBranchCard = props.isUsingStaffBranchCard;

  try {
    if (typeof props.data.sm_item_data === 'undefined') {
      return '';
    }
    if (conf.digitalTypes.includes(props.data.material_type)) {
      return '';
    }
    if (typeof props.data.sm_item_data[0] === 'undefined') {
      return '';
    }
    if (!isUsingStaffBranchCard) {
      if (props.data.is_copies_non_deleted === 0) { // public user and no circulating copies
        return '';
      }
    }

    const StatusDiv = (props) => {
      if (props.text === 'CHECK SHELVES') {
        return (
          <div><IconCalendarPlus></IconCalendarPlus> CHECK SHELVES</div>
        );
      }
      if (props.text.indexOf('DUE ') > -1) {
        let dueDate = props.text.replace('DUE ', '');
        let timestamp = Date.parse(dueDate);
        const dateObject = new Date(timestamp);
        let formattedDate = (dateObject.getMonth() + 1) + '/' + dateObject.getDate() +  '/' + dateObject.getFullYear();
        return (
          <div><IconCalendarTimes></IconCalendarTimes> DUE {formattedDate}</div>
        )
      }
      return (
        <div><IconCalendarTimes></IconCalendarTimes> {props.text}</div>
      )
    }

    const OpacLabel = (props) => {
      if (props.text === '') {
        return (<div></div>)
      } 
      return (
        <div><IconTag></IconTag> {props.text}</div>
      )
    }

    const ItemLocationRow = (props) => {
      const i = JSON.parse(props.data);
      let status = '';

      try {
        if (i.status.duedate) {
          status = 'DUE ' + i.status.duedate;
        } else {
          status = i.status.display;
        }
      } catch {}

      let locationName = i.location.name;

      try {
        if (locationName.match(/bklyn coll/i) !== null) {
          locationName = "Center for Brooklyn History";
        }
      } catch {}
      

      if (props.availableOnly) {
        if (status === 'CHECK SHELVES') {
          if (!i.suppressed) {
            return (
              <div className="item-location-row">
                <div className="notranslate"><IconLandmark></IconLandmark> {locationName}</div>
                <StatusDiv text={status}></StatusDiv>
                <div className="item-location-table-call-num notranslate"><IconHashtag></IconHashtag> {i.callNumber}</div>
                <OpacLabel text={i.opacLabel}></OpacLabel>
              </div>
            )
          }
        }
      } else {
        if (isUsingStaffBranchCard) {
          return (
            <div className="item-location-row">
              <div className="notranslate"><IconLandmark></IconLandmark> {locationName}</div>
              <StatusDiv text={status}></StatusDiv>
              <div className="item-location-table-call-num notranslate"><IconHashtag></IconHashtag> {i.callNumber}</div>
              <OpacLabel text={i.opacLabel}></OpacLabel>
            </div>
          )
        }
        // if not using staff branch card, filter out suppressed/missing etc
        if (!i.suppressed) {
          if (status === 'DELETE' || status === 'MISSING' || status === 'LOST' || status === 'BILLED') {// this should be unnecessary since we moved suppression to the status field on the backend in solr
            return '';
          }
          return (
            <div className="item-location-row">
              <div className="notranslate"><IconLandmark></IconLandmark> {locationName}</div>
              <StatusDiv text={status}></StatusDiv>
              <div className="item-location-table-call-num notranslate"><IconHashtag></IconHashtag> {i.callNumber}</div>
              <OpacLabel text={i.opacLabel}></OpacLabel>
            </div>
          )
        }
      }
      
    }

    const ItemGridMap = (props) => {
      if (props.availableOnly) {
        try {
          let countAvailable = 0;
          for (let i = 0; i < props.data.length; i++) {
            let decoded = JSON.parse(props.data[i]);
            if (decoded.status.display === 'CHECK SHELVES' && typeof decoded.status.duedate === 'undefined') {
              countAvailable++;
            }
          } 
          if (countAvailable === 0) {
            const noCopiesStyle = {borderBottom: 'none'};
            return (<div style={noCopiesStyle} className="item-location-row">Sorry, no copies are available now</div>)
          } else {
            return props.data.map((item) => <ItemLocationRow key={item} availableOnly={props.availableOnly} data={item}></ItemLocationRow>);
          }
        } catch (e) { console.log(e)}
        
      } else {
        return props.data.map((item) => <ItemLocationRow key={item} availableOnly={props.availableOnly} data={item}></ItemLocationRow>);
      }
    }

    const sortItemLocations = (a, b) => {
      const first = JSON.parse(a);
      const second = JSON.parse(b);
      try {
        if (first.location.name > second.location.name) {
          return 1;
        } else if (first.location.name < second.location.name) {
          return -1;
        } else {
          return 0;
        }
      } catch {}
    }

    try {
      props.data.sm_item_data.sort(sortItemLocations);
    } catch {}

    const toggleAvailableCopies = (e) => {
      if (availableChecked) {
        setAvailableChecked(false);
      } else {
        setAvailableChecked(true);
      }
    }
    
    const OrderCount = (props) => {
      let orderCount = 0;

      try {
        //orderCount = props.data.sm_orders.length;
        
        for (let i = 0; i < props.data.sm_orders.length; i++) {
          let copyCount = parseInt(props.data.sm_orders[i].replace(/ .+/, ''));
          orderCount += copyCount;
        }
          
      } catch {}

      if (orderCount === 0) {
        return '';
      }
      if (orderCount === 1) {
        return (
          <div className="order-count flex"><div><IconTruck /></div><div>1 more copy on order</div></div>
        )
      }
      return (
        <div className="order-count flex"><div><IconTruck /></div><div>{orderCount} more copies on order</div></div>
      )
    }

    return (
      <div className="Metadata">
        <h3>Holdings</h3>
        <div className="only-show-available-container">
          <input onChange={toggleAvailableCopies} checked={availableChecked} id="only-show-available" type="checkbox"></input>
          <label htmlFor="only-show-available">Only show available</label>
          <OrderCount data={props.data}></OrderCount>
        </div>
        <div className="holding-locations-table">
          <ItemGridMap availableOnly={availableChecked} data={props.data.sm_item_data}></ItemGridMap>
        </div>
      </div>
    )
  } catch {}
};

export default ItemLocationTable;