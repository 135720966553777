import IconSparkles from "../icons/iconSparkles";

const SearchSparkleTip = (props) => {

    const url = new URL(window.location);

    let giveTip = false;

    let innerMarkup = '';

    try {

        // TIPS GO HERE

        try { // search parameter only checks

            if (url.searchParams.get('search').toLocaleLowerCase() === 'gmail') {
                giveTip = true;
                innerMarkup = '<span class="bold">Suggestion:</span> Looking for <a target="_blank" href="https://mail.google.com/">gmail</a>?';
            }
    
            if (url.searchParams.get('search').toLocaleLowerCase() === 'google') {
                giveTip = true;
                innerMarkup = '<span class="bold">Suggestion:</span> Looking for <a target="_blank" href="https://google.com">Google search</a>?';
            }
        
            if (
                url.searchParams.get('search').toLocaleLowerCase() === 'what to read' || 
                url.searchParams.get('search').toLocaleLowerCase() === 'reading suggestions' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'suggestions' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'suggestion'
            ) {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> <a target="_blank" href="https://www.bklynlibrary.org/online-resources/novelist-plus">Novelist</a> is a free online resource for reading recommendations that you can access with your library card.';
            }
    
            if (
                url.searchParams.get('search').toLocaleLowerCase() === 'ask a tech' || 
                url.searchParams.get('search').toLocaleLowerCase() === 'tech help' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'help with tech' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'technology help' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'tech support' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'technology support' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'tech'
            ) {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> Try our free online resource <a target="_blank" href="https://www.bklynlibrary.org/online-resources/linkedin-learning">Linked-in Learning</a> for online tech classes.';
            }
    
    
            if (
                url.searchParams.get('search').toLocaleLowerCase() === 'addiction' || 
                url.searchParams.get('search').toLocaleLowerCase() === 'alcoholism' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'alcohol' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'suicide' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'drugs'
            ) {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> Our free online resource <a target="_blank" href="https://www.bklynlibrary.org/online-resources/findhelporg">Findhelp.org</a> may help.';
            }
    
            if (
                url.searchParams.get('search').toLocaleLowerCase() === 'test prep' || 
                url.searchParams.get('search').toLocaleLowerCase() === 'test help' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'ged' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'ged prep' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'ged test' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'hse prep' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'hse test' ||
                url.searchParams.get('search').toLocaleLowerCase() === 'hse'
            ) {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> <a target="_blank" href="https://www.bklynlibrary.org/online-resources/brainfuse-helpnow">Brainfuse</a> and <a target="_blank" href="https://www.bklynlibrary.org/online-resources/learningexpress-library">LearningExpress</a> have free online test prep!';
            }

        } catch {}
        

        try {
            if (url.searchParams.get('search').toLocaleLowerCase() === 'edwidge danticat') {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> Our <a target="_blank" href="https://www.bklynlibrary.org/blog/2024/11/08/haitian-heritage-book">Haitian Heritage Book Bingo Reading Challenge</a> is from now until November 21st.';
            }
        } catch {}

        try {
            if (url.searchParams.get('subjects').indexOf('Haitian') > -1) {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> Our <a target="_blank" href="https://www.bklynlibrary.org/blog/2024/11/08/haitian-heritage-book">Haitian Heritage Book Bingo Reading Challenge</a> is from now until November 21st.';
            }
        } catch {}

        try {
            if (
                url.searchParams.get('author') === 'Edwidge Danticat' ||
                url.searchParams.get('language') === 'Kreyòl | Haitian French Creole'
                ) {
                giveTip = true;
                innerMarkup = '<span class="bold">Tip:</span> Our <a target="_blank" href="https://www.bklynlibrary.org/blog/2024/11/08/haitian-heritage-book">Haitian Heritage Book Bingo Reading Challenge</a> is from now until November 21st.';
            }
        } catch {}
        

        if (giveTip === false) {
            return '';
        }
    
        const createMarkup = () => {
            return {__html: innerMarkup};
        }
    
        return (
            <div className="search-sparkle-tip">
                <div>
                    <IconSparkles />
                </div>
               
                <div dangerouslySetInnerHTML={createMarkup()}></div>
                
            </div>
        )

    } catch {}

}

export default SearchSparkleTip;