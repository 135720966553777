import configs from "../../functions/configs";
import postData from "../../functions/postData";
import { useEffect, useState } from "react";
import IconHashtag from "../icons/iconHashtag";
import IconLandmark from "../icons/iconLandmark";
import IconCalendarTimes from "../icons/iconCalendarTimes";
import IconCalendarPlus from "../icons/iconCalendarPlus";
import IconTag from "../icons/iconTag";

let holdingsGrabbed = false;

const HoldingsTable = (props) => {
  const conf = configs();
  const url = new URL(window.location.href);

  const [data, setData] = useState('');

  let duplicateHoldingsTitle = false;

  try {
    // overwrite copy limitation if is B&CC holding
    try {
      for (let i = 0; i < props.data.sm_item_data.length; i++) {
        if (props.data.sm_item_data[i].indexOf('BCC') > -1) {
          props.data.is_copies = 0;
          duplicateHoldingsTitle = true;
        }
      }
    } catch {}

    

    if (props.data.is_copies === 0 && props.data.suppressed === false && props.data.bs_on_order === false && props.data.ss_biblevel === 's') {
      console.log('hiii holdings');
      if (holdingsGrabbed === false) {
        
        postData(conf.baseAPI + '/sierra/sierra-return-holdings.php?b=' + url.searchParams.get('b'), "").then(res => {
          console.log('raw response', res);
          setData(res);
          holdingsGrabbed = true;
        });
      }
      
      const Holdings = () => {

        const Holding = (props) => {

          let libraryHas = '';
          try {
            for (let i = 0; i < props.data.varFields.length; i++) {
              if (props.data.varFields[i].fieldTag === 'h') {
                libraryHas += props.data.varFields[i].content + ' ';
              }
            }
          } catch {}
          libraryHas = libraryHas.trim();

          let holdingNote = '';
          try {
            for (let i = 0; i < props.data.varFields.length; i++) {
              if (props.data.varFields[i].fieldTag === 'i') {
                holdingNote += props.data.varFields[i].content + ' ';
              }
            }
          } catch {}
          holdingNote = holdingNote.trim();

          let lastReceived = '';
          try {
            for (let i = 0; i < props.data.checkins.length; i++) {
              if (props.data.checkins[i].copies_cnt === '1') {
                lastReceived = props.data.checkins[i].chron_level_i;
                if (props.data.checkins[i].chron_level_j) {
                  lastReceived += '-' + props.data.checkins[i].chron_level_j;
                }
                if (props.data.checkins[i].chron_level_k) {
                  lastReceived += '-' + props.data.checkins[i].chron_level_k;
                }
                /*
                if (props.data.checkins[i].note) {
                  lastReceived += ' (' + props.data.checkins[i].note + ')';
                }
                */
                break;
              }
            }
          } catch {}

          return (
            <div className="holding-table-item">
              <div><span className="bold">Location:</span> {props.data.location}</div>
              <div><span className="bold">Library has:</span> {libraryHas}</div>
              <div><span className="bold">Last received:</span> {lastReceived}</div>
              <div><span className="bold">Notes:</span> {holdingNote}</div>
            </div>
          )
        }

        try {
          return (
            data.map(item => <Holding key={item.id} data={item}></Holding>)
          )
        } catch {}
      }

     
      if (data.length > 0) {
        if (duplicateHoldingsTitle) {
          return (
            <div>
              <div>
                <Holdings></Holdings>
              </div>
            </div>
          )
        }
        return (
          <div>
            <h3>Holdings</h3>
            <div>
              <Holdings></Holdings>
            </div>
          </div>
        )
      }

      if (data.length === 0 && holdingsGrabbed === true && duplicateHoldingsTitle === false) {
        return (
          <div>
            <h3>Holdings</h3>
            <div>
              There no current holdings for this publication. <a href="https://www.bklynlibrary.org/contact">Contact us</a> for help.
            </div>
          </div>
        )
      }

    }
  } catch (err) {console.log(err)}
};

export default HoldingsTable;