import { useState } from "react";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import IconRobot from "../icons/iconRobot";
import CatalogFormatIcon from "../icons/catalogFormatIcon";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import returnBestImage from "../../functions/returnBestImage";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import toggleOverlay from "../../functions/toggleOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import MagnifyingGlassIcon from "../icons/magnifyingGlassIcon";
import IconEllipses from "../icons/iconEllipsis";
import getData from "../../functions/getData";
import deleteCookie from "../../functions/deleteCookie";
import getCookie from "../../functions/getCookie";


let dataGrabbed = false;
const history = {};
let optedOut = false;

const HistoryContainer = (props) => {

    const conf = configs();

    const [historyState, setHistoryState] = useState('');
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('');
    const [switchedAccountState, setSwitchedAccountState] = useState('');

    let switchedAccounts = getCookie('bplswitchedhistory');

    if (switchedAccounts !== '') {
        delete history.data;
    }

    if (dataGrabbed === false || switchedAccounts !== '') {
        getData(conf.baseAPI + "/user/return-history.php").then((res) => {
            console.log(res);
            dataGrabbed = true;
            deleteCookie('bplswitchedhistory');
            if (switchedAccounts !== '') {
                setSwitchedAccountState(Date.now());
            }
            if (typeof res.readingHistoryActivation !== 'undefined') {
                setHistoryState('optedOut');
                optedOut = true;
            } else {
                history.data = res;
                setHistoryState('loaded');
                optedOut = false;
            }
        });
    }

    const optInHistory = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        postData(conf.baseAPI + "/sierra/sierra-opt-in-reading-history.php", "").then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            launchSuccessOverlay("", "");
            setHistoryState('justOptedIn');
        });
    }

    if (dataGrabbed === false || switchedAccounts !== '') {
        // 
        return (
            <div className="my-account-content-container">
                <div>Beep Boop. Gathering data...  <IconRobot></IconRobot></div>
    
            </div>
        )
    }
    
    if (historyState === 'optedOut' || optedOut) {
        return (
            <div className="my-account-content-container">
    
                <div className="white-tile-container">
                    
                    <div>You are not opted in to record checkout history. Brooklyn Public Library only records checkout history for patrons at their request. <a target="_blank" href="https://www.bklynlibrary.org/use-the-library/policy/privacy-statement">Learn more about our privacy policies</a>.</div>
                    <br></br> 
                    <div>Click the button below if you wish to start recording your checkout history from this day forward.</div>
                    <br></br>    
                    <div><button onClick={optInHistory} className="default-blue-button">Start recording history</button></div>
    
                </div>
    
            </div>
        )
    }

    if (historyState === 'justOptedIn') {
        return (
            <div className="my-account-content-container">
    
                <div className="white-tile-container">

                    <h3>You are now opted in!</h3>
                    
                    <div>Your checkout history will be recorded here from today moving forward. Your checkout history before enabling this feature is not recorded by the library. <a target="_blank" href="https://www.bklynlibrary.org/use-the-library/policy/privacy-statement">Learn more about our privacy policies</a>.</div>
    
                </div>
    
            </div>
        )
    }

    if (historyState === 'justOptedOut') {
        return (
            <div className="my-account-content-container">
    
                <div className="white-tile-container">

                    <h3>You have opted out of checkout history</h3>
                    
                    <div>Your checkout history has been deleted and will not be recorded moving forward. <a target="_blank" href="https://www.bklynlibrary.org/use-the-library/policy/privacy-statement">Learn more about our privacy policies</a>.</div>
    
                </div>
    
            </div>
        )
    }

    const forceHistoryOptOutState = () => {
        setHistoryState('justOptedOut');
    }

    const Entry = (props) => {

        try {

            if (typeof props.data.hide !== 'undefined') {
                if (props.data.hide === true) {
                    return '';
                }
            }

            let img = '';
            let link = "/item?b=" + props.data.bib_id;
    
            let date = new Date(props.data.checkout_gmt);
            let dateFormatted = date.toLocaleDateString("en-US");

            try {
                img = returnBestImage(props.data.solrData);
            } catch {}

     
            return (
                <div className="my-account-catalog-record">

                    <div className="my-account-due-date-container"><div>CHECKED OUT:</div><div className="due-date-time">{dateFormatted}</div></div>

                    <div className="flex-small-gap">
                        <div>
                            <img onError={fallbackImageHandler} onLoad={onLoadImageHandler} alt={props.data.solrData.title} src={img}></img>
                        </div>

                        <div className="my-account-text-container">
                            <div className="my-account-catalog-title"><a href={link}>{props.data.solrData.title}</a></div>
                            <div className="my-account-catalog-author">{props.data.solrData.author}</div>
                            <div className="my-account-catalog-material-type"><CatalogFormatIcon format={props.data.solrData.material_type}></CatalogFormatIcon> {props.data.solrData.material_type}</div>
                        </div>
                    </div>

                    <div className="my-account-catalog-actions">
                        <a className="my-account-action-link hide" href="#">Delete from history</a>
                    </div>
                </div>
            )
        } catch {}
    }

    const Entries = (props) => {
        try {
            return props.data.map(item => <Entry key={item.history_id} data={item}></Entry>)
        } catch {}
    }

    const searchHistory = (e) => {
        try {
            const searchVal = document.querySelector('#search-history-input').value.toLowerCase();
            for (let i = 0; i < history.data.length; i++) {
                let match = false;
                try {
                    if (history.data[i].solrData.title.toLowerCase().includes(searchVal)) {
                        match = true;
                    }
                } catch {}
                
                try {
                    if (history.data[i].solrData.author.toLowerCase().includes(searchVal)) {
                        match = true;
                    }
                } catch {}
                
                try {
                    for (let x = 0; x < history.data[i].solrData.subjects.length; x++) {
                        if (history.data[i].solrData.subjects[x].toLowerCase().includes(searchVal)) {
                            match = true;
                        }
                    }
                } catch {}

                if (match === false) {
                    history.data[i].hide = true;
                } else {
                    history.data[i].hide = false;
                }
            }
            setSearch(searchVal);
        } catch {}
    }

    const sortHistory = (e) => {
        try {
            if (e.currentTarget.value === 'recent-checkout') {
                history.data.sort((a, b) => {
                    const aDate = new Date(a.checkout_gmt);
                    const bDate = new Date(b.checkout_gmt);
                    const aTime = aDate.getTime();
                    const bTime = bDate.getTime();
                    if (aTime > bTime) {
                        return -1;
                    } else if (aTime < bTime) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                
            } else if (e.currentTarget.value === 'oldest-checkout') {
                history.data.sort((a, b) => {
                    const aDate = new Date(a.checkout_gmt);
                    const bDate = new Date(b.checkout_gmt);
                    const aTime = aDate.getTime();
                    const bTime = bDate.getTime();
                    if (aTime > bTime) {
                        return 1;
                    } else if (aTime < bTime) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else if (e.currentTarget.value === 'author') {
                history.data.sort((a, b) => {
                    if (typeof a.solrData.author_raw === 'undefined') {
                        return 0;
                    }
                    if (a.solrData.author_raw > b.solrData.author_raw) {
                        return 1;
                    } else if (a.solrData.author_raw < b.solrData.author_raw) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else if (e.currentTarget.value === 'title') {
                history.data.sort((a, b) => {
                    if (a.solrData.title > b.solrData.title) {
                        return 1;
                    } else if (a.solrData.title < b.solrData.title) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            }
            setSort(e.currentTarget.value);
        } catch {}
    }

    const toggleDropdown = (e) => {
        try {
            e.currentTarget.nextSibling.classList.toggle('hide');
            const box = e.currentTarget.getBoundingClientRect();
            if (box.left < 200) {
                e.currentTarget.nextSibling.firstChild.style.left = '0px';
            }
        } catch {}
    }

    const launchOptOutOverlay = (e) => {
        document.querySelector('.history-more-actions-container .more-actions-btn').click();
        toggleOverlay('#history-opt-out-overlay');
    }

    try {
        if (typeof history.data !== 'undefined') {
            if (history.data.length === 0) {
                return (
                    <div className="my-account-content-container">
            
                        <div className="white-tile-container">
                            
                            <div>You are opted into reading history, but have no reading history recorded at the moment. After you check out items from the library, those items will appear here the following day.</div>
            
                        </div>
            
                    </div>
                )
            } else {
                // history exists
                let savings = 0;
                let savingsFormatted = '';
                let totalFormatted = '0';
                try {
                    savings = history.data.length * 15;
                    savingsFormatted = savings.toLocaleString();
                    totalFormatted = history.data.length.toLocaleString();
                } catch {}

                const marginAdded = {
                    marginTop: "12px",
                    marginBottom: "12px"
                }

                const marginBottom = {
                    marginBottom: "20px"
                }

                return (
                    <div className="my-account-content-container">

                        <div style={marginBottom}>
                            Based on your history, you've <a target="_blank" href="https://www.bklynlibrary.org/library-use-calculator">saved roughly <span className="bold">${savingsFormatted}</span></a> using the library! This is only physical checkout history. For digital checkout history, visit our eBook vendor <a href="https://brooklyn.overdrive.com/account/history">OverDrive</a>.
                        </div>

                        <div className="account-sort-and-more-flex">
                            <div className="account-sort-fiter-container">
                                <div id="search-history-input-container">
                                    <label htmlFor="search-history-input" className="sr-only">Search history</label>
                                    <input onKeyUp={searchHistory} type="text" placeholder="Search history" autoComplete="off" id="search-history-input"></input>
                                    <MagnifyingGlassIcon />
                                </div>

                                <label htmlFor="history-sort" className="sr-only">Sort history</label>
                                <select onChange={sortHistory} id="history-sort">
                                    <option value="recent-checkout">Sort by most recently checked out</option>
                                    <option value="oldest-checkout">Sort by oldest checkout</option>
                                    <option value="author">Sort by author</option>
                                    <option value="title">Sort by title</option>
                                </select>
                            </div>

                            <div className="history-more-actions-container">
                                <button onClick={toggleDropdown} title="More actions" className="more-actions-btn">
                                    <IconEllipses />
                                    <span className="sr-only">More actions</span>
                                </button>
                                <div className="more-actions-dropdown-container hide">
                                    <div className="more-actions-dropdown">
                                        <button onClick={launchOptOutOverlay} className="list-export-btn main-color">Opt out of history</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className="main-color bold" style={marginAdded}>
                            {totalFormatted} item(s)
                        </div>

                        

                        <div className="checkouts-holds-container">
                            <Entries data={history.data}></Entries>
                        </div>
                        <button onClick={forceHistoryOptOutState} id="force-history-opt-out-state" className="hide">history opt out state</button>
                    </div>
                )
            }
        }
    } catch {
        return (
            <div>Beep Boop. Gathering data... &nbsp; <IconRobot></IconRobot></div>
        )
    }
    
}

export default HistoryContainer;