import configs from "../../functions/configs";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import arrayUnique from "../../functions/arrayUnique";
import GetThisItemButton from "../shared/getThisItemButton";
import AddToListButton from "../shared/addToListButton";
import returnBestImage from "../../functions/returnBestImage";
import RebalancingClaimButton from "../shared/rebalancingClaimButton";

const SearchResultSingleCatalog = (props) => {

  try {

    const conf = configs();

    const updateSearchData = props.updateSearchData;

    const url = new URL(window.location.href);

    let titleLink = "/item?b=" + props.data.id;

    let format = '';
    try {
      format = props.data.material_type;
      if (typeof props.data.publishYear !== "undefined") {
        format += " - " + props.data.publishYear;
      }
    } catch {}
  
    let title = '';
    try {title = props.data.title; } catch {}
  
    let imgSource = returnBestImage(props.data);
  
    let imageAlt = 'jacket art';
    try {imageAlt = props.data.tm_title[0].replace(/[\'\"\<\>]/, '') + ' ' + imageAlt;} catch {};
  
    let summary = "";

    try {
      summary = props.data.summary[0];
      summary = summary.substring(0, conf.summaryLength);
      summary.trim();
      if (!summary.match(/\.$/)) {
        summary += '...';
      }
    } catch {}

    try {
      if (typeof props.data.annotation !== 'undefined') {
        if (props.data.annotation !== '' && props.data.annotation !== null) {
          summary = props.data.annotation;
        }
      }
    } catch {}

  
    const AuthorSpan = (props) => {

      const AuthorLink = (props) => {
        try {
          let href = "/?catalog=true&author=" + encodeURIComponent(props.data);
          return (
            <a className="notranslate" href={href}>{props.data}</a>
          )
        } catch {}
      }
      let author = '';
      try {author = props.data.author;} catch {};
      if (author === '' || typeof author !== 'string') {
        return (<span></span>)
      }
      return (<span>by <AuthorLink data={props.data.author}/></span>)
    }
  
    const AvailabilityDiv = (props) => {

      const noShrink = {flexShrink: "0"}

      if (props.data.available === true) {

        return (<div style={noShrink}><span className="color-available">Available Now</span></div>)

      } else {

        try {

          if (props.data.suppressed) {
            return (<div style={noShrink}><span className="color-pending">Edition no longer in collection</span></div>)
          }
      
          if (props.data.not_in_solr) {
            return (<div style={noShrink}><span className="color-pending">Edition no longer in collection</span></div>)
          }

          if (props.data.bs_all_lib_use_only) {
            return (<div style={noShrink}><span className="color-pending">Wait List</span></div>)
          }

          if (props.data.bs_on_order) {
            return (<div style={noShrink}><span className="color-pending">On Order</span></div>)
          }

        } catch {}

        return (<div style={noShrink}><span className="color-pending">Wait List</span></div>)

      }

    }
  
    const SubjectPillButtons = (props) => {

      const subjects = [];
  
      try {
        if (typeof props.data.subjects !== 'undefined') {
            for (let x = 0; x < props.data.subjects.length; x++) {
              subjects.push(props.data.subjects[x]);
            }
        }
      } catch {}
      
      const subjectsUnique = arrayUnique(subjects);
  
      subjectsUnique.sort();
  
      if (subjectsUnique.length > conf.subjectPillLimit) {
        try {subjectsUnique.splice(conf.subjectPillLimit); } catch {}
      }
  
      return subjectsUnique.map((item) => <SubjectPillButton key={item} name={item} />);
    }
  
    const SubjectPillButton = (props) => {
      let isActivated = 'no';
      if (url.searchParams.get('subjects') !== null) {
        const values = url.searchParams.get('subjects').split('||');
        if (values.indexOf(props.name) > -1) {
          isActivated = 'yes';
        }
      }
      return (<button onClick={updateSearchData} filtertype='catalog-tag' filterval={props.name} activated={isActivated} className="subject-pill-btn no-color">{props.name}</button>);
    }

    const CallNumber = (props)=> {
      try {
        const callNumberExcludes = ['eBook', 'eAudiobook', 'Online Video', 'Board Game'];

        if (typeof props.data.call_number !== 'undefined') {
          
          if (props.data.call_number === '' || props.data.call_number === null) {
            return ''
          }
          
          if (callNumberExcludes.indexOf(props.data.material_type) === -1) {
            return (
              <div><div className="flex"><div>|</div><div><span className="bold notranslate">Call Number:</span> {props.data.call_number}</div></div></div>
            )
          }
        }
      } catch {}
    }
    
    let resultDetailActionsClasses = 'result-detail-actions';

    try {
      if (props.data.suppressed) {
        resultDetailActionsClasses = 'result-detail-actions hide';
      }
  
      if (props.data.not_in_solr) {
        resultDetailActionsClasses = 'result-detail-actions hide';
      }
    } catch {}
    
    return (
      <div className="result-container">
        <div className="image-container">
          <a href={titleLink}>
            <img onLoad={onLoadImageHandler} onError={fallbackImageHandler} src={imgSource} alt={imageAlt}></img>
          </a>
        </div>
        <div className="result-detail-container">
          <div className="result-detail-text">
            <div className="result-type">{format}</div>
            <div className="result-title">
            <a href={titleLink}>
              {title}
            </a>
            </div>
            <div className="result-author">
              <AuthorSpan data={props.data} />
            </div>
            <div className="availability-container">
              <div className="flex">
              <AvailabilityDiv data={props.data}></AvailabilityDiv>
              <CallNumber data={props.data}></CallNumber>
              </div>
            </div>
            <div className="result-summary">{summary}</div>
            <div className={resultDetailActionsClasses}>
              <GetThisItemButton setSelectedBibId={props.setSelectedBibId} bib={props.data.id}></GetThisItemButton>
              <AddToListButton setSelectedBibId={props.setSelectedBibId} bib={props.data.id}></AddToListButton>
              <RebalancingClaimButton data={props.data} />
            </div>
            <div className="subject-pill-btn-container"><SubjectPillButtons data={props.data}></SubjectPillButtons></div>
          </div>
        </div>
      </div>
    )

  } catch (e) {console.log(e)}
      
};

export default SearchResultSingleCatalog;
