const IconArchiveBox = (props) => {

    let height = 16;
    
    if (typeof props.height !== 'undefined') {
        height = props.height
    } 

    return (
        <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M32 128l0 352 448 0 0-352L32 128zm128 64l192 0 0 64-192 0 0-64z"/><path class="fa-primary" d="M0 32H512v96H0V32z"/></svg>
    )
}

export default IconArchiveBox;